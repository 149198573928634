//#region IMPORTS
import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import { required, min, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import Configuration from "@/core/config/configuration";
import NotificationService from "@/core/services/notification.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import { GET_PRE_SIGNED_URL } from "@/core/services/graphql/queries/s3/getPreSignedUrl";
//#endregion

//#region Validation rules
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "The {_field_} is required"
});

extend("min", {
  ...min,
  message: "{_field_} must be at least 1 character"
});

extend("max", {
  ...max,
  message: "{_field_} has a maximum of 160 characters"
});

//#endregion

export default {
  components: { ValidationProvider, ValidationObserver },
  apollo: {
    create_pre_signed_url: {
      query: () => GET_PRE_SIGNED_URL,
      skip() {
        return true;
      },
      watchLoading(isLoading) {
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      result({ data, networkStatus }) {
        if (networkStatus == 7) {
          var preSignedUrl = data.create_pre_signed_url;
          var uploadForm = this.generateUploadForm(preSignedUrl);
          this.uploadFileToThePreSignedUrl(preSignedUrl, uploadForm);
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Group", route: "/group/navigation-menu" },
      { title: "Sentia Standard Pricing" }
    ]);
  },
  data() {
    return {
      objectName: null,
      form: {
        csvFile: "",
        objectName: ""
      }
    };
  },
  methods: {
    csvUploaded(e) {
      this.form.csvFile = e;
    },
    uploadCsv() {
      if (this.form.objectName.endsWith(".csv")) {
        this.objectName = `${Configuration.value("object_name")}/${
          this.form.objectName
        }`;
      } else {
        this.objectName = `${Configuration.value("object_name")}/${
          this.form.objectName
        }.csv`;
      }

      this.$apollo.queries.create_pre_signed_url.setVariables({
        bucket_name: Configuration.value("bucket_name"),
        object_name: this.objectName
      });

      this.$apollo.queries.create_pre_signed_url.skip = false;
      this.$apollo.queries.create_pre_signed_url.refetch();
    },
    generateUploadForm(preSignedUrl) {
      var uploadForm = new FormData();
      uploadForm.append("key", `${preSignedUrl.fields.key}`);
      uploadForm.append(
        "AWSAccessKeyId",
        `${preSignedUrl.fields.AWSAccessKeyId}`
      );
      uploadForm.append(
        "x-amz-security-token",
        `${preSignedUrl.fields.x_amz_security_token}`
      );
      uploadForm.append("policy", `${preSignedUrl.fields.policy}`);
      uploadForm.append("signature", `${preSignedUrl.fields.signature}`);
      uploadForm.append("file", this.form.csvFile, this.objectName);
      return uploadForm;
    },
    uploadFileToThePreSignedUrl(preSignedUrl, uploadForm) {
      this.$store.dispatch(PAGE_REQUEST_DATA, true);
      fetch(preSignedUrl.url, {
        method: "POST",
        body: uploadForm
      })
        .then(response => response)
        .then(response => {
          if (response.ok) {
            NotificationService.success("Successfully uploaded file");
          } else {
            NotificationService.error("File upload failed");
          }
        })
        .catch(error => {
          console.error(error);
          NotificationService.error(
            this,
            "Something went wrong while uploading"
          );
        })
        .finally(() => {
          this.$store.dispatch(PAGE_REQUEST_DATA, false);
          this.$refs.uploadForm.reset();
        });
    }
  }
};
